import React, { lazy, Suspense, useRef } from 'react'
import { Platform } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { SpaceMonitor, useConfig, useIsAuthed, useMatrixFullStateSync } from '@vatom/sdk/react'
import { i18n } from '@vatom/utils'
import type { AppStackParamList } from '@vatom/wallet-routes'
import { AppRoutes } from '@vatom/wallet-routes'
// import { LoaderView } from '@vatom/wombo'
import * as Linking from 'expo-linking'
import { useLocales } from 'expo-localization'
import { observer } from 'mobx-react-lite'

import { useBroadcastSession } from '../../hooks/useBroadcastSession'
import { useBrowserCompatibilityWarning } from '../../hooks/useBrowserCompatibilityWarning'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useForceUpdate } from '../../hooks/useForceUpdate'
import { useNavigationPending } from '../../hooks/useNavigationPending'
import { removeBodyScroll } from '../../hooks/useRemoveBodyScroll.web'
import logger from '../../logger'
//
import { useSDKHandlers } from '../../utils/WalletSdk/hooks'
//
const LayoutScrollHandlerProvider = lazy(() => import('../../hooks/useLayoutScrollHandler'))
const CustomFavicon = lazy(() => import('../../components/Favicon'))
const ModalReedem = lazy(() => import('../../components/ModalRedeem/ModalReedem'))
const RewardsCard = lazy(() => import('../../components/RewardsCard'))
const Acquire = lazy(() => import('../../screens/Acquire'))
const AcquirePubVariation = lazy(() => import('../../screens/AcquirePubVariation'))
const NftFiltersModal = lazy(() => import('../../screens/ActionSheets/NftFiltersModal'))
const BackupToCloud = lazy(() => import('../../screens/BackupToCloud'))
const BlockchainNetwork = lazy(() => import('../../screens/BlockChainNetworks/BlockChainNetworks'))
const BlockChainNetworkAdd = lazy(
  () => import('../../screens/BlockChainNetworks/BlockChainNetworksAdd')
)
const BlockChainNetworkEdit = lazy(
  () => import('../../screens/BlockChainNetworks/BlockChianNetworksEdit')
)
const BlockChainWallets = lazy(() => import('../../screens/BlockChainWallets/BlockChainWallets'))
const CameraScreen = lazy(() => import('../../screens/Camera'))
const ChangeBackupPassword = lazy(() => import('../../screens/ChangeBackupPassword'))
const Claim = lazy(() => import('../../screens/Claim'))
const CoinNavigation = lazy(() => import('../../screens/CoinNavigation'))
const AddQuestion = lazy(
  () =>
    import(
      '../../screens/Communities/components/AddModals/modal-components/modal-questions-components/AddQuestion'
    )
)
const Questions = lazy(
  () =>
    import(
      '../../screens/Communities/components/AddModals/modal-components/modal-questions-components/Questions'
    )
)
const AddSketch = lazy(
  () =>
    import(
      '../../screens/Communities/components/AddModals/modal-components/modal-sketches-components/AddSketch'
    )
)
const Sketches = lazy(
  () =>
    import(
      '../../screens/Communities/components/AddModals/modal-components/modal-sketches-components/Sketches'
    )
)
const RoomNewMessage = lazy(() => import('../../screens/Communities/screens/AddMessageModal'))
const RoomMembers = lazy(() => import('../../screens/Communities/screens/Members'))
const RoomReply = lazy(() => import('../../screens/Communities/screens/Reply'))
const RoomView = lazy(() => import('../../screens/Communities/screens/Room'))
const RoomThread = lazy(() => import('../../screens/Communities/screens/Thread'))
const SyncContainer = lazy(() => import('../../screens/Communities/SyncContainer'))
const Connect = lazy(() => import('../../screens/Connect/Connect'))
const LoginCallback = lazy(() => import('../../screens/Connect/LoginCallback'))
const DeleteFromCloud = lazy(() => import('../../screens/DeleteFromCloud'))
const DmScreen = lazy(() => import('../../screens/DmScreen/DmScreen'))
const DropNFT = lazy(() => import('../../screens/DropNFT'))
const EditAddressName = lazy(() => import('../../screens/EditAddressName'))
const EditProfile = lazy(() => import('../../screens/EditProfile'))
const FindToken = lazy(() => import('../../screens/FindToken/FindToken'))
const FollowList = lazy(() => import('../../screens/FollowList'))
const FungibleTokenConfirmation = lazy(() => import('../../screens/FungibleTokens/confirmation'))
const FungibleTokenDetail = lazy(() => import('../../screens/FungibleTokens/detail'))
const FungibleTokenRecipient = lazy(() => import('../../screens/FungibleTokens/recipient'))
const FungibleTokenTransfer = lazy(() => import('../../screens/FungibleTokens/transfer'))
const Home = lazy(() => import('../../screens/Home'))
const ImportWalletRecover = lazy(() => import('../../screens/ImportWallet/screen/recover'))
const ImportWalletStart = lazy(() => import('../../screens/ImportWallet/screen/start'))
const InvitePeople = lazy(() => import('../../screens/InvitePeople'))
const LogoutCallback = lazy(() => import('../../screens/LogoutCallback'))
const ManageAccounts = lazy(() => import('../../screens/ManageAccounts'))
const MapAR = lazy(() => import('../../screens/MapAR'))
const MintNFT = lazy(() => import('../../screens/MintNFT'))
const MintSuccess = lazy(() => import('../../screens/MintSuccess'))
const NewWalletBackup = lazy(() => import('../../screens/NewWallet/screens/backup'))
const NewWalletConfirm = lazy(() => import('../../screens/NewWallet/screens/confirm'))
const NewWalletStart = lazy(() => import('../../screens/NewWallet/screens/start'))
const NFTDetail = lazy(() => import('../../screens/NFTDetail/index.web'))
const ProfileOtherUser = lazy(() => import('../../screens/ProfileOtherUser'))
const ProfileUser = lazy(() => import('../../screens/ProfileUser'))
const CloudBackupList = lazy(() => import('../../screens/RestoreFromCloud/screens/list'))
const RestoreFromCloud = lazy(() => import('../../screens/RestoreFromCloud/screens/password'))
const SellNFT = lazy(() => import('../../screens/SellNFT'))
const SendConfirmation = lazy(() => import('../../screens/SendConfirmation/index.web'))
const SendNFT = lazy(() => import('../../screens/SendNFT'))
const SendNFTConfirmation = lazy(() => import('../../screens/SendNFTConfirmation'))
const SendNFTShare = lazy(() => import('../../screens/SendNFTShare'))
const SendToChainConfirmation = lazy(
  () => import('../../screens/SendToChainConfirmation/SendToChainConfirmation')
)
const Settings = lazy(() => import('../../screens/Settings/Settings'))
const SettingsMetamask = lazy(() => import('../../screens/SettingsMetamask'))
const ShowPrivateKey = lazy(() => import('../../screens/ShowPrivateKey'))
const Space = lazy(() => import('../../screens/Space'))
const TokenAR = lazy(() => import('../../screens/TokenAR'))
const WalletAddressManagement = lazy(() => import('../../screens/WalletAddressManagement'))
const DebugPanel = lazy(() => import('../../utils/DebugPanel'))

const Stack = createNativeStackNavigator<AppStackParamList>()

const isWeb = Platform.OS === 'web'

const modalListeners = {
  focus: () => {
    isWeb && removeBodyScroll().add()
  },
  blur: () => {
    isWeb && removeBodyScroll().remove()
  },
  beforeRemove: () => {
    isWeb && removeBodyScroll().remove()
  }
}

export const AppStack = observer(() => {
  const renderCount = useRef(0)
  const { isDark } = useBusinessTheme()

  renderCount.current++
  // always put the login screen first on the stack even when restoring initialState
  const isAuthed = useIsAuthed()

  console.info('[AppStack] renderCount:', renderCount)

  return (
    <LayoutScrollHandlerProvider>
      <Subscriptions />
      <SpaceMonitor />
      <CustomFavicon />

      <Suspense fallback={null}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            gestureEnabled: false
          }}
          // rn navigation 7
          // screenLayout={({ children }) => <Suspense fallback={<LoaderView />}>{children}</Suspense>}
        >
          {!isAuthed && (
            <>
              <Stack.Screen name={AppRoutes.connect} component={Connect} />
              <Stack.Screen name={AppRoutes.businessConnect} component={Connect} />
            </>
          )}
          {isAuthed && (
            <>
              <Stack.Screen
                name={AppRoutes.home}
                component={Home}
                options={{ gestureEnabled: false }}
              />
              <Stack.Screen
                name={AppRoutes.BusinessProxy}
                component={Home}
                getId={({ params }) => {
                  return params?.business
                }}
              />
              {/* NFT DETAILS  */}
              <Stack.Group
                screenOptions={{
                  gestureDirection: 'horizontal',
                  animation: 'slide_from_bottom'
                }}
              >
                <Stack.Screen
                  name={AppRoutes.NFTDetail}
                  component={NFTDetail}
                  listeners={modalListeners}
                />
                <Stack.Screen
                  name={AppRoutes.NFTDetail_Business}
                  component={NFTDetail}
                  getId={({ params }) => `${params.business}-${params.tokenId}`}
                  listeners={modalListeners}
                />
                <Stack.Screen name={AppRoutes.SendNFT} component={SendNFT} />
                <Stack.Screen
                  name={AppRoutes.SendNFT_Business}
                  component={SendNFT}
                  getId={({ params }) => `${params.business}-${params.tokenId}`}
                />
                <Stack.Screen name={AppRoutes.SendNFTShare} component={SendNFTShare} />
                <Stack.Screen
                  name={AppRoutes.SendNFTShare_Business}
                  component={SendNFTShare}
                  getId={({ params }) => `${params.business}-${params.tokenId}`}
                />
                <Stack.Screen name={AppRoutes.DropNFT} component={DropNFT} />
                <Stack.Screen
                  name={AppRoutes.DropNFT_Business}
                  component={DropNFT}
                  getId={({ params }) => `${params.business}-${params.tokenId}`}
                />
                <Stack.Screen name={AppRoutes.SellNFT} component={SellNFT} />
                <Stack.Screen
                  name={AppRoutes.SellNFT_Business}
                  component={SellNFT}
                  getId={({ params }) => `${params.business}-${params.tokenId}`}
                />
                <Stack.Screen name={AppRoutes.MintNFT} component={MintNFT} />
                <Stack.Screen
                  name={AppRoutes.MintNFT_Business}
                  component={MintNFT}
                  getId={({ params }) => `${params.business}-${params.tokenId}`}
                />
              </Stack.Group>
              <Stack.Screen name={AppRoutes.FindToken} component={FindToken} />
              <Stack.Screen name={AppRoutes.FindToken_Business} component={FindToken} />
              <Stack.Screen
                name={AppRoutes.sendConfirmation}
                component={SendConfirmation}
                options={{
                  presentation: 'transparentModal'
                }}
                // had to remove this cause scrolling is not working
                // listeners={modalListeners}
              />
              <Stack.Screen name={AppRoutes.editProfile} component={EditProfile} />
              <Stack.Screen name={AppRoutes.manageAccounts} component={ManageAccounts} />
              <Stack.Screen name={AppRoutes.settingsMetamask} component={SettingsMetamask} />
              <Stack.Screen name={AppRoutes.invitePeople} component={InvitePeople} />
              <Stack.Screen name={AppRoutes.MintSuccess} component={MintSuccess} />
              <Stack.Screen name={AppRoutes.BackupToCloud} component={BackupToCloud} />
              <Stack.Screen name={AppRoutes.RestoreFromCloud} component={RestoreFromCloud} />
              <Stack.Screen name={AppRoutes.DeleteFromCloud} component={DeleteFromCloud} />
              <Stack.Screen
                name={AppRoutes.ChangeBackupPassword}
                component={ChangeBackupPassword}
              />
              <Stack.Screen name={AppRoutes.CloudBackupList} component={CloudBackupList} />
              <Stack.Screen name={AppRoutes.NewWalletStart} component={NewWalletStart} />
              <Stack.Screen name={AppRoutes.NewWalletBackup} component={NewWalletBackup} />
              <Stack.Screen name={AppRoutes.NewWalletConfirm} component={NewWalletConfirm} />
              <Stack.Screen name={AppRoutes.ImportWalletStart} component={ImportWalletStart} />
              <Stack.Screen name={AppRoutes.ImportWalletRecover} component={ImportWalletRecover} />
              <Stack.Screen
                name={AppRoutes.WalletAddressManagement}
                component={WalletAddressManagement}
              />
              <Stack.Screen name={AppRoutes.ShowPrivateKey} component={ShowPrivateKey} />
              <Stack.Screen name={AppRoutes.EditAddressName} component={EditAddressName} />
              <Stack.Screen
                name={AppRoutes.sendToChainConfirmation}
                component={SendToChainConfirmation}
              />
              <Stack.Group
                screenOptions={{
                  presentation: 'modal'
                }}
              >
                <Stack.Screen
                  name={AppRoutes.MapAR}
                  component={MapAR}
                  options={{}}
                  listeners={modalListeners}
                />
                <Stack.Screen
                  name={AppRoutes.TokenAR}
                  component={TokenAR}
                  options={{}}
                  listeners={modalListeners}
                />
                <Stack.Screen
                  name={AppRoutes.CameraScreen}
                  component={CameraScreen}
                  options={{}}
                  listeners={modalListeners}
                />
              </Stack.Group>
              <Stack.Screen
                name={AppRoutes.profileUser}
                component={ProfileUser}
                // options={{
                //   contentStyle: {
                //     backgroundColor: isDark
                //       ? theme.colors.grayCool[900]
                //       : theme.colors.grayCool[50],
                //     height: '100%'
                //   }
                // }}
              />
              <Stack.Screen name={AppRoutes.Metaverse} component={Space} />
              <Stack.Screen name={AppRoutes.followList} component={FollowList} />
              <Stack.Screen name={AppRoutes.profileOtherUser} component={ProfileOtherUser} />
              <Stack.Screen
                name={AppRoutes.profileOtherUser_Business}
                component={ProfileOtherUser}
              />
              <Stack.Screen
                name={AppRoutes.NftFilters}
                component={NftFiltersModal}
                options={{
                  presentation: 'transparentModal',
                  animation: 'fade'
                }}
                listeners={modalListeners}
              />
            </>
          )}
          {/* <Stack.Screen name={AppRoutes.CommunitiesHome} component={CommunitiesHomeWithBusiness} /> */}
          <Stack.Screen name={AppRoutes.Room} component={RoomView} />
          <Stack.Screen name={AppRoutes.RoomMembers} component={RoomMembers} />
          <Stack.Screen name={AppRoutes.RoomThread} component={RoomThread} />
          <Stack.Screen name={AppRoutes.CommunitiesRoom} component={RoomView} />
          <Stack.Screen name={AppRoutes.CommunitiesRoomMembers} component={RoomMembers} />
          <Stack.Screen name={AppRoutes.CommunitiesRoomThread} component={RoomThread} />
          <Stack.Group screenOptions={{ presentation: 'fullScreenModal' }}>
            <Stack.Screen name={AppRoutes.RoomNewMessage} component={RoomNewMessage} />
            <Stack.Screen name={AppRoutes.CommunitiesRoomNewMessage} component={RoomNewMessage} />

            <Stack.Screen name={AppRoutes.RoomReply} component={RoomReply} />
            <Stack.Screen name={AppRoutes.CommunitiesRoomReply} component={RoomReply} />

            <Stack.Screen name={AppRoutes.RoomNewQuestion} component={AddQuestion} />
            <Stack.Screen name={AppRoutes.CommunitiesRoomNewQuestion} component={AddQuestion} />

            <Stack.Screen name={AppRoutes.RoomSketchList} component={Sketches} />
            <Stack.Screen name={AppRoutes.CommunitiesRoomSketchList} component={Sketches} />

            <Stack.Screen name={AppRoutes.RoomNewSketch} component={AddSketch} />
            <Stack.Screen name={AppRoutes.CommunitiesRoomNewSketch} component={AddSketch} />

            <Stack.Screen name={AppRoutes.RoomQuestionList} component={Questions} />
            <Stack.Screen name={AppRoutes.CommunitiesRoomQuestionList} component={Questions} />
          </Stack.Group>
          <Stack.Screen name={AppRoutes.AcquirePubVariation} component={AcquirePubVariation} />
          <Stack.Screen name={AppRoutes.AcquirePubVariation1} component={AcquirePubVariation} />
          <Stack.Screen name={AppRoutes.AcquirePubVariation2} component={AcquirePubVariation} />
          <Stack.Screen
            name={AppRoutes.Claim}
            component={Claim}
            navigationKey={isAuthed ? 'authed-claim' : 'non-authed-claim'}
          />
          <Stack.Screen name={AppRoutes.Acquire} component={Acquire} />
          <Stack.Screen name={AppRoutes.Acquire_Business} component={Acquire} />
          <Stack.Screen
            navigationKey={isAuthed ? 'authed-logoutcb' : 'non-authed-logoutcb'}
            name={AppRoutes.LogoutCallback}
            component={LogoutCallback}
          />
          {Platform.OS === 'web' && (
            <Stack.Screen name={AppRoutes.LoginCallback} component={LoginCallback} />
          )}
          <Stack.Screen name={AppRoutes.coin} component={CoinNavigation} options={{}} />
          <Stack.Screen
            name={AppRoutes.CoinDetail_Business}
            component={CoinNavigation}
            options={{}}
          />
          {/** 🔥 Your screens go here */}
          <Stack.Screen
            name={AppRoutes.DmScreen}
            component={DmScreen}
            options={{
              gestureEnabled: true
            }}
          />
          <Stack.Screen name={AppRoutes.Settings} component={Settings} options={{}} />
          <Stack.Screen
            name={AppRoutes.BlockChainWallets}
            component={BlockChainWallets}
            options={{}}
          />
          <Stack.Screen
            name={AppRoutes.BlockChainNetwork}
            component={BlockchainNetwork}
            options={{}}
          />
          <Stack.Screen
            name={AppRoutes.BlockChainNetworkAdd}
            component={BlockChainNetworkAdd}
            options={{}}
          />
          <Stack.Screen
            name={AppRoutes.BlockChainNetworkEdit}
            component={BlockChainNetworkEdit}
            options={{}}
          />
          <Stack.Screen
            name={AppRoutes.FungibleTokenDetail}
            component={FungibleTokenDetail}
            options={{}}
          />
          <Stack.Screen
            name={AppRoutes.FungibleTokenTransfer}
            component={FungibleTokenTransfer}
            options={{}}
          />
          <Stack.Screen
            name={AppRoutes.FungibleTokenRecipient}
            component={FungibleTokenRecipient}
            options={{}}
          />
          <Stack.Screen
            name={AppRoutes.FungibleTokenConfirmation}
            component={FungibleTokenConfirmation}
            options={{}}
          />
          <Stack.Screen
            name={AppRoutes.sendNFTConfirmation}
            component={SendNFTConfirmation}
            // had to remove this cause scrolling is not working
            // listeners={modalListeners}
          />
        </Stack.Navigator>
      </Suspense>
      {isAuthed ? (
        <>
          <ModalReedem />
          <RewardsCard />
        </>
      ) : null}
      <DebugPanel />
    </LayoutScrollHandlerProvider>
  )
})

export const openDeepLink = async () => {
  if (Platform.OS === 'web') {
    const initialUrl = await Linking.getInitialURL()
    logger.info('[openDeepLink] initialUrl', initialUrl)
    logger.info('[openDeepLink] window.location.href', window.location.href)
    return initialUrl
  }
  try {
    const initialUrl = await Linking.getInitialURL()

    if (initialUrl) {
      const urlObject = new URL(initialUrl)

      // for some reason ios opens a browser if the scheme is https
      const deeplink =
        Platform.OS === 'ios'
          ? `${urlObject.hostname}:/${urlObject.pathname}?${urlObject.searchParams}`
          : initialUrl
      const canOpen = await Linking.canOpenURL(deeplink)
      if (canOpen) {
        // await Linking.openURL(deeplink)
      } else {
        logger.error('Cannot open url: ', deeplink, initialUrl)
      }
    }

    return initialUrl
  } catch (error) {
    logger.error('Error opening deep link', error)
  }
  return null
}

const Subscriptions = observer(() => {
  useBroadcastSession()

  const isAuthed = useIsAuthed()
  useMatrixFullStateSync({
    enabled: !!isAuthed,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

  useSDKHandlers()
  useBrowserCompatibilityWarning()
  useForceUpdate()
  useNavigationPending()
  console.info('[Subscriptions] isAuthed:', isAuthed)

  const locale = useLocales()

  const config = useConfig()

  i18n.locale = config?.features.language ?? locale?.[0]?.languageCode ?? 'en'

  return <SyncContainer />
})
