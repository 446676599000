import { Sentry } from '@vatom/sdk/core'
import { Channels, useCrateStore, useNotificationsStore } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { Status, Toast } from '@vatom/wombo'
import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import logger from '../logger'
import type { BVatomSnapshot, BVatomTokenType } from '../models/BVatomToken'
import { BVatomToken } from '../models/BVatomToken'
import type { ActionPayload } from '../modules/Vatom/model/Action'
import type { VatomPayload } from '../modules/Vatom/model/BVatom'
import type { FacePayload } from '../modules/Vatom/model/Face'

import { VatomRegionStore } from './BVatomRegion'

const className = 'BVatomInventoryRegionStore'

export const VatomsPerPage = 50

const shouldSkipNotificationsActionNames = ['Activate', 'Acquire', 'Pickup'] as const

type InventoryResponse = {
  payload: InventoryPayload
  message: any
}
type InventoryPayload = {
  actions: any[]
  faces: any[]
  vatoms: any[]
  hits: number
}

export const BVatomInventoryRegionStore = VatomRegionStore.named(className)
  .props({
    className,
    _tokens: types.optional(types.map(BVatomToken), {}),
    // lastStableSync: types.maybe(types.number),
    // currentHash: types.maybe(types.string),
    shownNotifications: types.optional(types.array(types.string), [])
  })
  .views(self => ({
    /** Our state key is the current user's ID */
    get stateKey() {
      return `inventory:VatomNet:${self._id}`
    },
    get vatoms(): BVatomTokenType[] {
      return self.tokens as BVatomTokenType[]
    },
    get isLoading() {
      console.log('self._isLoading vatom inventory: ', self._isLoading)
      return self._isLoading //|| self.api.store.assetProvider.length === 0
    },
    get isFetching() {
      console.log('self._isFetching vatom inventory: ', self._isLoading)
      return self._isFetching
    },
    get inventoryApi() {
      const apiEndpoint = self.rootStore.service.vatoms
      return apiEndpoint
    }
  }))
  .actions(self => ({
    storeShownNotification(id: string) {
      self.shownNotifications.push(id)
    },
    matches(id: string, descriptor?: string) {
      return id === 'inventory'
      // && descriptor === self.address
    },
    getItem(id: string) {
      return self.vatoms.find(t => t.id === id)
    }
  }))
  .actions(self => {
    let _maxPage = 0
    let _lastPage = 0
    let _lastKey = ''
    let _initialLoad = true

    function _setMaxPage(totalHits?: number, maxAmount?: number) {
      if (!totalHits || !maxAmount) {
        _maxPage = 0
        return
      }
      const maxPage = totalHits <= maxAmount ? 0 : Math.ceil(totalHits / maxAmount)
      _maxPage = maxPage
    }
    function _setLastPage(number?: number) {
      _lastPage = number ?? 0
    }
    function _setLastKey(key: string) {
      _lastKey = key
    }
    function _resetKeys() {
      _setMaxPage(0)
      _setLastPage(0)
      _setLastKey('')
      _initialLoad = true
    }

    type FetchTokensParams = {
      parentId?: string
      businessId?: string
      pageParam?: number
      limit?: number
      sortBy?: 'when_modified' | 'when_created' | 'name'
      sortOrder?: 'asc' | 'desc'
    }

    const defaultFetchTokensParams: FetchTokensParams = {
      // parentId: '.',
      businessId: '',
      pageParam: 0,
      limit: VatomsPerPage,
      sortBy: 'when_modified',
      sortOrder: 'desc'
    }

    async function fetchTokens(params: FetchTokensParams = defaultFetchTokensParams) {
      const { parentId, businessId, pageParam, limit, sortBy, sortOrder } = params

      try {
        const apiEndpoint = self.inventoryApi

        const payload = {
          // parent_id: parentId,
          business_id: businessId,
          limit,
          page: pageParam,
          sort_by: sortBy,
          sort_order: sortOrder
        }

        const res = await apiEndpoint.post(`/me/inventory`, JSON.stringify(payload))
        const data = res.data as InventoryResponse

        // TODO: Fix ts error
        if (data?.message === null) {
          // Invalid request
          throw new Error('Missing payload on response')
        }
        return data?.payload || null
      } catch (error) {
        console.log('ERROR: fetchTokens:', error)
        return null
      }
    }

    function getNextPage(currentKey: string) {
      if (_lastKey !== currentKey) {
        // reset pages do to key change
        return 0
      }
      const maxPage = _maxPage ?? 0
      const lastPage = _lastPage ?? 0
      if (lastPage === maxPage && maxPage !== 0) {
        return undefined
      }
      return lastPage < maxPage ? lastPage + 1 : 0
    }

    type FetchPageParams = {
      parentId?: string
      businessId?: string
      page?: number
      limit?: number
      sortBy?: 'when_modified' | 'when_created' | 'name'
      sortOrder?: 'asc' | 'desc'
    }

    async function fetchPage({
      // parentId,
      businessId,
      page,
      limit,
      sortBy,
      sortOrder
    }: FetchPageParams = {}) {
      try {
        // Pause websocket events
        self.pauseMessages()
        self.setIsLoading(true)
        self.setIsFetching(true)

        const FETCH_LIMIT = VatomsPerPage
        const args = {
          // parentId: parentId ?? '.',
          businessId: businessId ?? '',
          limit: limit ?? FETCH_LIMIT,
          sortBy: sortBy ?? 'when_modified',
          sortOrder: sortOrder ?? 'desc'
        }

        const fetchKey = Object.entries(args).flat().join(':')

        const pageParam = page ?? getNextPage(fetchKey)
        if (pageParam === undefined) {
          return // no more pages
        }
        const tokensPayload = await fetchTokens({
          ...args,
          pageParam
        })

        _setMaxPage(tokensPayload?.hits, FETCH_LIMIT)
        _setLastPage(pageParam)
        _setLastKey(fetchKey)

        if (tokensPayload && tokensPayload?.vatoms) {
          const newTokens: BVatomSnapshot[] = []
          for (const token of tokensPayload.vatoms) {
            const actions =
              tokensPayload?.actions?.filter((action: ActionPayload) =>
                action.name.includes(token['vAtom::vAtomType'].template)
              ) ?? []
            const faces =
              tokensPayload?.faces?.filter(
                (face: FacePayload) => face.template === token['vAtom::vAtomType'].template
              ) ?? []

            newTokens.push(self.mapObject(token.id, token, faces, actions))
          }
          if (_initialLoad) {
            // The first time we load, we want to replace the tokens in case we missed any updates
            self.startSockets()
            self.setTokens(newTokens)

            _initialLoad = false
          } else {
            self.addTokens(newTokens)
          }
        }
      } catch (error) {
        console.log('ERROR: VatomInventoryRegion.fetchPage:', error)
        _resetKeys()
      } finally {
        // Resume websocket events
        self.resumeMessages()
        self.setIsLoading(false)
        self.setIsFetching(false)
      }
    }

    function clear() {
      _resetKeys()
      self._tokens.clear()
    }

    return {
      fetchPage,
      clear,
      resetKeys: _resetKeys
    }
  })
  .actions(self => ({
    async load(): Promise<void> {
      logger.info(
        '[VatomInventoryRegion.new.load] init isLoggedIn',
        self.rootStore.dataPool.sessionStore.isLoggedIn
      )

      if (self.isLoading && self.isFetching) {
        logger.warn('[VatomInventoryRegion.new.load] we already loading...')
        return
      }

      try {
        // Start the websocket server if it is not running
        self.startSockets()
        await self.fetchPage()
      } catch (err) {
        console.log('LOG: VatomInventoryRegion.load > err:', err)
      } finally {
        logger.info('[VatomInventoryRegion.new.load] loaded')
      }
    }
  }))
  .actions(self => ({
    async reload(): Promise<void> {
      self.resetKeys()
      self.load()
    }
  }))
  .actions(self => {
    // Contrive a super() concept
    const superProcessMessage = self.processMessage
    return {
      async processMessage(msg: any) {
        logger.info('[VatomInventoryRegion.processMessage] start')
        // Call super
        superProcessMessage(msg)

        // We only handle inventory update messages after this.
        if (msg.msg_type !== 'inventory') {
          return
        }

        // Get vatom ID
        const vatomID = msg.payload && msg.payload.id
        if (!vatomID) {
          return logger.warn(
            `[DataPool > BVWebSocketRegion] Got websocket message with no vatom ID in it: `,
            JSON.stringify(msg, null, 4)
          )
        }
        // Check if this is an incoming or outgoing vatom
        const currentBVUserId = self.api.store.userID
        if (
          msg.payload.old_owner === currentBVUserId &&
          msg.payload.new_owner !== currentBVUserId
        ) {
          // Vatom is no longer owned by us
          self.removeTokens([vatomID])
        } else if (
          msg.payload.old_owner !== currentBVUserId &&
          msg.payload.new_owner === currentBVUserId
        ) {
          try {
            // Vatom is now our inventory! Fetch vatom payload
            const response = await self.api.client.request(
              'POST',
              '/v1/user/vatom/get',
              { ids: [vatomID] },
              true
            )

            // Add faces to new objects list
            if (!response.vatoms || response.vatoms.length === 0) {
              Sentry.captureMessage(`No vatom found in API response: ${vatomID}`)
            }

            if (!response.faces || response.faces.length === 0) {
              Sentry.captureMessage(`No faces found in API response: ${vatomID}`)
            }

            if (response.vatoms.length === 0) return

            // Add vatom to new objects list
            const newVatoms: BVatomSnapshot[] = []
            // Add vatoms to the list
            for (const v of response.vatoms as VatomPayload[]) {
              const actions = response.actions.filter(($: ActionPayload) =>
                $.name.includes(v['vAtom::vAtomType'].template)
              )
              const faces = response.faces.filter(
                ($: FacePayload) => $.template === v['vAtom::vAtomType'].template
              )
              newVatoms.push(self.mapObject(v.id, v, faces, actions))
            }

            // Add new objects
            logger.info('In Inventory Region: adding new objects', newVatoms.length)
            const sendNotification = async (token: BVatomSnapshot) => {
              if (token) {
                let fromAvatar: string | undefined = undefined
                let fromName = 'A User'
                let business

                const businessId = token?.private['studio-info-v1']?.businessId
                const objectDefinitionId = token?.private?.analytics_data?.objectDefinitionId
                if (businessId) {
                  business = await self.api.rootStore.vatomIncApi.getPublicBusinessProfile(
                    businessId
                  ) //vatomIncApi.getPublicBusinessProfile(token.studioInfo.businessId)

                  const vatomFeatures = business?.pageConfig?.features?.vatom
                  if (vatomFeatures?.disableNewTokenToast) return
                  if (
                    objectDefinitionId &&
                    vatomFeatures?.ignoreNewTokenToast?.includes(objectDefinitionId)
                  )
                    return
                }
                if (token.lastOwner === token.author) {
                  fromName = business?.displayName || 'A Business'
                  fromAvatar = business?.logoSrc
                } else if (token.lastOwner) {
                  // Fetch user info
                  const user = await self.api.rootStore.vatomIncApi.getPublicProfile(
                    token.lastOwner
                  )
                  fromName = user?.name || 'A User'
                  fromAvatar = user?.picture
                }

                useNotificationsStore.getState().addNotification(Channels.wallet, token.id)

                Toast({
                  status: Status.info,
                  title: `${fromName} sent you a ${token.properties.title} object`,
                  avatar: fromAvatar
                })
              }
            }

            const isEmbedded = useVatomWalletSdkStore.getState().isEmbedded
            if (isEmbedded) {
              useVatomWalletSdkStore.getState().setinventoryWasUpdated(true)
            }

            const shouldShowToast = !shouldSkipNotificationsActionNames.includes(
              msg.payload.action_name
            )

            const walletSDKhideNotifications =
              useVatomWalletSdkStore.getState().walletConfig.features.disableNewTokenToast

            if (
              shouldShowToast &&
              !useCrateStore.getState().isRewardsActive &&
              !walletSDKhideNotifications
            ) {
              newVatoms.forEach(vatom => {
                if (self.shownNotifications.indexOf(vatom.id) === -1) {
                  self.storeShownNotification(vatom.id)
                  sendNotification(vatom)
                }
              })
            }

            self.addTokens(newVatoms)
          } catch (err) {
            console.error('inventory processMessage err: ', err)
            if (err instanceof Error) Sentry.captureError(err)
          }
        } else {
          // Logic error, old owner and new owner cannot be the same
          // logger.warn(`[DataPool > BVWebSocketRegion] Logic error in WebSocket message, old_owner and new_owner shouldn't be the same: ${vatomRef.id}`)
        }
      }
    }
  })
  .actions(self => ({
    afterCreate() {
      // self.setIsLoading(true)
    }
  }))

export type BVatomInventoryRegionStoreType = Instance<typeof BVatomInventoryRegionStore>
export type BVatomInventoryRegion = BVatomInventoryRegionStoreType
export type BVatomInventoryRegionSnapshot = SnapshotOut<typeof BVatomInventoryRegionStore>
