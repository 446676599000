import { useColorScheme } from 'react-native'
import { ThemesEnum } from '@vatom/experience-sdk'
import type { BrandConfigColors, BusinessSnapshot } from '@vatom/sdk/core'
import type { BrandConfig, PageConfig } from '@vatom/sdk/react'
import { useConfig } from '@vatom/sdk/react'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'
import { theme } from '@vatom/wombo'

import { useBusinessSelector } from './useBusinessSelector'

const usePageTheme = (t: string = ThemesEnum.light) => {
  if (t === ThemesEnum.dark) {
    return {
      backgroundRgba: 'rgb(0,0,0);',
      background: theme.colors.grayCool[900],
      borderColor: theme.colors.grayDarkMode[700],
      colorHeader: theme.colors.grayDarkMode[900],
      primaryText: theme.colors.textDarkMode[100],
      text: theme.colors.textDarkMode[100],
      active: 'white',
      inactive: '#868e96',
      disabled: '#29313a',
      buttonFollow: {
        border: '#ffffff4d',
        text: '#B3B4B7',
        backgroundColor: '#6100FF'
      },
      buttonFollowing: {
        border: theme.colors.grayDarkMode[700],
        text: '#B3B4B7',
        backgroundColor: theme.colors.grayCool[900]
      }
    }
  }

  return {
    backgroundRgba: 'rgba(255, 255, 255, 1)',
    background: theme.colors.white,
    borderColor: '#EFEFEF',
    colorHeader: theme.colors.grayCool[700],
    primaryText: theme.colors.textLightMode[100],
    text: theme.colors.textLightMode[600],
    active: theme.colors.grayCool[700],
    inactive: theme.colors.lightText,
    disabled: theme.colors.extraLightText,
    buttonFollow: {
      border: theme.colors.grayCool[400],
      text: theme.colors.black,
      backgroundColor: theme.colors.white
    },
    buttonFollowing: {
      border: theme.colors.grayCool[400],
      text: theme.colors.black,
      backgroundColor: theme.colors.grayCool[400]
    }
  }
}

const getIsDark = (themeType: string) => themeType === ThemesEnum.dark

type BusinessTheme = Record<keyof BrandConfigColors, string>
const getBusinessTheme = (
  brandConfig: BusinessSnapshot['brandConfig'],
  type: string = ThemesEnum.light
) => {
  const colorMode = brandConfig?.mode || type
  const themedColors = brandConfig?.colors
    ? Object.entries(brandConfig.colors).reduce<BusinessTheme>(
        (acc, [colorKey, scheme]) => ({
          ...acc,
          [colorKey]: scheme[colorMode]
        }),
        {} as BusinessTheme
      )
    : ({} as BusinessTheme)

  return {
    theme: themedColors,
    isDark: colorMode && getIsDark(colorMode)
  }
}

const useBusinessThemeConfig = ({
  pageConfig,
  brandConfig
}: {
  pageConfig?: PageConfig
  brandConfig?: BrandConfig
}) => {
  const config = useConfig()
  const sdkThemeOverride = config.features.systemThemeOverride

  const scheme = useColorScheme()
  const systemTheme = scheme === 'light' ? ThemesEnum.light : ThemesEnum.dark
  const themeType =
    brandConfig?.mode ?? pageConfig?.theme?.pageTheme ?? sdkThemeOverride ?? systemTheme
  const pageTheme = usePageTheme(themeType)
  let businessTheme = null
  if (brandConfig) {
    businessTheme = getBusinessTheme(brandConfig)
  }

  return {
    pageTheme,
    brandTheme: businessTheme && businessTheme.theme,
    isDark: getIsDark(themeType)
  }
}

const useBusinessTheme = () => {
  const { business } = useBusinessSelector()
  return useBusinessThemeConfig({
    pageConfig: business?.pageConfig,
    brandConfig: business?.brandConfig
  })
}

export { useBusinessTheme, useBusinessThemeConfig }
